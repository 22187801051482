import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Social from '../components/Social'
import BlogCards from '../components/BlogCards'
import SEO from '../components/SEO'
import background from '../img/background.svg'
import Particles from 'react-particles-js'
import particlesConfig from '../styles/particles-config.js'

export const IndexPageTemplate = ({ title, description }) => (
  <section>
    <div className="container h-screen max-w-5xl mx-auto">
      <div className="relative z-20 rounded shadow-xl h-3/4 bg-gradient-to-b from-purple-500 via-pink-700 to-yellow-600 dark:from-primary dark:via-purple-900 dark:to-pink-600">
        <Particles className="absolute z-auto w-full h-full" params={particlesConfig} />
        <div className="flex flex-col items-center content-center justify-center h-full space-y-2 text-center md:space-y-4 lg:pb-32">
          <h1 className="z-20 px-4 text-3xl font-bold md:text-4xl text-primary dark:text-secondary animate-pulse">
          {description}
          </h1>
          <h2 className="z-20 text-2xl font-bold md:text-3xl">{title}</h2>
          <Social />
          <Link
            className="z-20 px-5 py-3 text-sm font-medium leading-snug text-white transition duration-150 ease-in-out bg-purple-900 border border-transparent rounded-md lg:text-base hover:bg-purple-700 dark:bg-primary dark:hover:bg-purple-700 focus:outline-none focus:shadow-outline"
            to="/contact"
          >
            Contactez-moi
          </Link>
        </div>
        <img
          alt="background"
          className="absolute z-10 rounded-b-md -bottom-1"
          src={background}
        />
      </div>
      {/*<div className="text-right">
        <button
          className="m-8 transition duration-300 ease-in-out rounded-full cursor-pointer animate-bounce hover:text-gray-900"
          aria-label="See below"
        >
          <svg
            className="w-8 h-8 md:w-12 md:h-12"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 14l-7 7m0 0l-7-7m7 7V3"
            ></path>
          </svg>
        </button>
      </div>*/}
    </div>
    {/* <div className="container">
      <h3 className="mt-0 mb-2 text-2xl font-normal leading-normal md:text-4xl">
        Derniers blogs !
      </h3>
      <BlogCards />
    </div> */}
  </section>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <IndexPageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
      }
    }
  }
`
